<template>
  <div class="screen-right-tips">
    <div :class="{'content': true, hide}">
      <a class="item" href="https://im.7x24cc.com/phone_webChat.html?accountId=N000000015742&chatId=6bb1e11d-94e8-4af1-a07f-a2e8f1e6a68d">
        <img src="../../../assets/images/common/online.png">
        <div>在线客服</div>
      </a>
      <a class="item" href="tel://400-6121-666">
        <img src="../../../assets/images/common/mobile.png">
        <div>客服热线</div>
      </a>
      <!-- <a v-if="isWxBrosser" class="item" href='https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzAwNDI1MDkzMg==&scene=124&uin=&key=&devicetype=Windows+10+x64&version=63030532&lang=zh_CN&a8scene=7&fontgear=2'>
        <img src="../../../assets/images/common/wechat.png">
        <div>公众号</div>
      </a> -->
      <div class="item" @click='() => {showPic = !showPic}'>
        <img src="../../../assets/images/common/wechat.png">
        <div>公众号</div>
      </div>
      <a class="item" href="https://m.qjdchina.com/download">
        <img src="../../../assets/images/common/download.png">
        <div>下载APP</div>
      </a>
      <div class="arrow"></div>
    </div>
    <div class="btn-area" @click="() => {hide = !hide;showPic = false}">
      <img src="../../../assets/images/common/btn.png">
    </div>
    <img v-if="!hide && showPic" class="qrcode" src="../../../assets/images/common/weixin.png">
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPic: false,
      hide: true
    }
  },
  mounted() {
    
  },
  destroyed() {
    
  },
  methods: {
  }
}
</script>

<style lang="scss">
.screen-right-tips{
  z-index: 50;
  position: fixed;
  right: 0;
  bottom: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .content{
    opacity: 1;
    width: 130px;
    height: 478px;
    background-color: #fff;
    box-shadow: 0px 8px 28px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px 0px 0px 8px;
    padding: 0 16px;
    transition: all 0.2s;
    overflow: hidden;
    .item{
      position: relative;
      display: block;
      padding: 15px 0;
      text-align: center;
      border-bottom: 1Px solid #F1F1F1;
      img{
        display: block;
        width: 46px;
        margin: 0 auto;
        margin-bottom: 10px;
      }
      &:nth-last-child(2){
        border-bottom: none;
      }
    }
    .arrow{
      position: absolute;
      bottom: 100px;
      left: 46px;
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid #FFF;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.08));
    }
    &.hide{
      height: 0;
      opacity: 0;
    }
  }
  .btn-area{
    text-align: center;
    margin: 20px 0 0 20px;
    background-color: #fff;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 46px;
    box-shadow: 0px 8px 28px 0px rgba(0, 0, 0, 0.08);
    img{
      width: 42px;
    }
  }
  .qrcode{
    position: absolute;
    width: 200px;
    left: -200px;
    top: 200px;
  }
}
</style>