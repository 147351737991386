import Layout from '@/views/Layout'

export default [
  {
    path: '/',
    redirect: 'home',
    component: Layout,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import(/*webpackChunkName: 'Home'*/'@/views/Home')
      },
      {
        path: 'friend',
        name: 'partner',
        component: () => import(/*webpackChunkName: 'Home'*/'@/views/Partner')
      },
      {
        path: 'friend/cooperate',
        name: 'cooperate',
        component: () => import(/*webpackChunkName: 'Home'*/'@/views/Partner/Cooperate')
      },
      {
        path: 'news',
        name: 'news',
        component: () => import(/*webpackChunkName: 'Home'*/'@/views/News')
      },
      {
        path: 'news/detail/:id',
        name: 'newsDetail',
        component: () => import(/*webpackChunkName: 'Home'*/'@/views/News/Detail')
      },
      {
        path: 'about',
        name: 'about',
        component: () => import(/*webpackChunkName: 'Home'*/'@/views/About')
      },
    ]
  },
  {
    path: '/IOSgulide',
    name: 'IOSgulide',
    component: () => import(/*webpackChunkName: 'Home'*/'@/views/IOSgulide')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/*webpackChunkName: 'Home'*/'@/views/Download')
  },
  {
    path: '/download/ys',
    name: 'downloadYs',
    component: () => import(/*webpackChunkName: 'Home'*/'@/views/Download')
  },
  // 风险专家app下载地址
  {
    path: '/downRisk',
    name: 'downRisk',
    component: () => import(/*webpackChunkName: 'Home'*/'@/views/DownRisk')
  },
]