import Layout from '@/views/Layout'

export default [
  {
    path: '/product',
    component: Layout,
    children: [
      {
        path: '',
        name: 'product',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/index')
      },
      {
        path: 'supplychain',
        name: 'supplychain',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/Supplychain')
      },
      {
        path: 'sale',
        name: 'sale',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/Sale')
      },
      {
        path: 'bidding',
        name: 'bidding',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/Bidding')
      },
      {
        path: 'projectManagement',
        name: 'projectManagement',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/ProjectManagement')
      },
      {
        path: 'contract',
        name: 'contract',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/Contract')
      },
      {
        path: 'salesTools',
        name: 'salesTools',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/SalesTools')
      },
      {
        path: 'crm',
        name: 'crm',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/CRM')
      },
      {
        path: 'erp',
        name: 'erp',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/ERP')
      },
      {
        path: 'layeringDealer',
        name: 'layeringDealer',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/LayeringDealer')
      },
      {
        path: 'riskEngine',
        name: 'riskEngine',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/RiskEngine')
      },
      {
        path: 'eye',
        name: 'eye',
        // meta: {title: '仟金眼'},
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/Eye')
      },
      {
        path: 'developersAssess',
        name: 'developersAssess',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/DevelopersAssess')
      },
      {
        path: 'projectAppraisal',
        name: 'projectAppraisal',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/ProjectAppraisal')
      },
      {
        path: 'dataCockpit',
        name: 'dataCockpit',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/DataCockpit')
      },
      {
        path: 'resources',
        name: 'resources',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/Resources')
      },
      {
        path: 'open',
        name: 'open',
        component: () => import(/*webpackChunkName: 'Product'*/'@/views/Product/Open')
      },
    ]
  }
]