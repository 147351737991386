<template>
  <header class="layout-header">
    <a class="logo" href="/"></a>
    <Menu></Menu>
  </header>
</template>

<script>
import Menu from './components/menu'
export default {
  components: {
    Menu,
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/mixins.scss";
.layout-header {
  padding: 17px 40px 15px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  .logo {
    background: url("~@/assets/images/common/logo.png") no-repeat;
    width:176px;
    height: 66px;
    display: block;
    background-size: contain;
  }
}
</style>