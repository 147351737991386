import Layout from '@/views/Layout'

export default [
  {
    path: '/solution',
    redirect: 'home',
    component: Layout,
    children: [
      {
        path: 'factory',
        name: 'factory',
        component: () => import(/*webpackChunkName: 'Solution'*/'@/views/Solution/Factory')
      },
      {
        path: 'company',
        name: 'company',
        component: () => import(/*webpackChunkName: 'Solution'*/'@/views/Solution/Company')
      },
    ]
  }
]