<template>
  <div class="layout">
    <qjd-header></qjd-header>
    <router-view></router-view>
    <qjd-footer></qjd-footer>
    <RightTip></RightTip>
  </div>
</template>

<script>
import QjdHeader from './QjdHeader'
import QjdFooter from './QjdFooter'
import RightTip from './components/rightTip'
export default {
  components: {
    QjdHeader,
    QjdFooter,
    RightTip
  }
}
</script>