<template>
  <div class="menu-total">
    <div class="menu" @click="showMenu = true"></div>
    <Popup v-model="showMenu" position="right" :style="{ height: '100%', width: '8.89rem' }">
      <div class="title">
        <a class="logo" href="/"></a>
        <Icon name="cross" color='#A9AFB8' @click='showMenu = false'/>
      </div>
      <Collapse v-model="activeNames" class='list'>
        <Cell title="首页" class='item1' @click='toPage("home")'/>
        <CollapseItem title="产品与服务" class='list1' name="1">
          <CollapseItem title="供应链Saas服务" class="list2" name="3">
            <Cell title="供应链协同" class="item3" @click='toPage("supplychain")'/>
            <Cell title="赊销系统" class="item3" @click='toPage("sale")'/>
            <Cell title="招投标管理" class="item3" @click='toPage("bidding")'/>
            <Cell title="项目管理" class="item3" @click='toPage("projectManagement")'/>
            <Cell title="智能合同" class="item3" @click='toPage("contract")'/>
            <Cell title="销售工具" class="item3" @click='toPage("salesTools")'/>
            <Cell title="CRM" class="item3" @click='toPage("crm")'/>
            <Cell title="ERP" class="item3" @click='toPage("erp")'/>
          </CollapseItem>
          <CollapseItem title="数据Saas服务" class="list2" name="4">
            <Cell title="经销商分层" class="item3" @click='toPage("layeringDealer")'/>
            <Cell title="风险引擎" class="item3" @click='toPage("riskEngine")'/>
            <Cell title="仟金眼" class="item3" @click='toPage("eye")'/>
            <Cell title="客户评估" class="item3" @click='toPage("developersAssess")'/>
            <Cell title="项目评估" class="item3" @click='toPage("projectAppraisal")'/>
            <Cell title="数据驾驶舱" class="item3" @click='toPage("dataCockpit")'/>
          </CollapseItem>
          <Cell title="资源赋能" class="item2" @click='toPage("resources")'/>
          <Cell title="开放平台" class="item2" @click='toPage("open")'/>
        </CollapseItem>
        <CollapseItem title="解决方案" class="list1" name="2">
          <Cell title="品牌厂家" class="item2" @click='toPage("factory")'/>
          <Cell title="小微企业" class="item2" @click='toPage("company")'/>
        </CollapseItem>
        <Cell title="合作伙伴" class='item1' @click='toPage("partner")'/>
        <Cell title="新闻中心" class='item1' @click='toPage("news")'/>
        <Cell title="关于仟金顶" class='item1' @click='toPage("about")'/>
      </Collapse>
    </Popup>
  </div>
</template>

<script>
import { Popup, Icon, Cell, CellGroup, Collapse, CollapseItem } from 'vant';
export default {
  components: {
    Popup, Icon, Cell, CellGroup, Collapse, CollapseItem
  },
  data() {
    return {
      showMenu: false,
      activeNames: [],
    }
  },
  methods: {
    toPage(name) {
      this.showMenu = false
      this.$router.push({name})
    }
  }
}
</script>
<style lang="scss">
.menu-total{
  height: 54px;
  .menu {
    background: url("~@/assets/images/common/menu.png") no-repeat;
    width:50px;
    height: 50px;
    display: block;
    background-size: contain;
  }
  .title{
    padding: 15px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1Px solid #E8E8E8;
    .logo {
      background: url("~@/assets/images/common/logo.png") no-repeat;
      width:176px;
      height: 66px;
      display: block;
      background-size: contain;
    }
    .van-icon-cross{
      font-size: 40px;
    }
  }
  .list{
    .list1, .item1{
      font-size: 28px;
      line-height: 40px;
      border-bottom: 1Px solid #E8E8E8;
      font-weight: bold;
    }
    .list1{
      .van-cell--clickable{
        padding: 30px 40px;
        font-weight: bold;
      }
      .van-collapse-item__title--expanded{
        color: #CEB287;
      }
      .van-collapse-item__content{
        border-top: 1Px solid #E8E8E8;
        padding: 0;
        padding-left: 51px;
      }
    }
    .item1{
      padding: 30px 40px;
    }
    .list2{
      border-bottom: 1Px solid #E8E8E8;
      .van-cell--clickable{
        padding: 30px 40px 30px 0;
        color: #252631;
        font-weight: normal;
      }
      .van-collapse-item__content{
        padding-left: 30px;
      }
    }
    .item2, .item3{
      color: #252631;
      border-bottom: 1Px solid #E8E8E8;
      padding: 30px 40px 30px 0;
      font-weight: normal;
    }
    .item2:nth-last-child(1), .item3:nth-last-child(1){
      border-bottom: none;
    }
    .item3{
      font-size: 24px;
    }
  }
  .van-cell::after, .van-cell--clickable, .van-collapse-item, .van-collapse-item--border::after, .van-hairline--top-bottom::after{
    border-width: 0;
    border-top: none;
    border-bottom: none;
  }
}
</style>